import { Link } from "gatsby"
import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import Img from 'gatsby-image'

class ArticleFeatured extends Component {
  render() {
    return (
      <StaticQuery query={graphql`query ArticleFeaturedQuery {
        allNodeArticle(limit: 1, sort: {order: [DESC, DESC], fields: [sticky,created]}, filter: {promote: {eq: true}}) {
          edges {
            node {
              id
              title
              body {
                value
                summary
              }
              field_subtitle
              created
              sticky
              promote
              path {
                alias
              }
              relationships {
                field_cover {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 736, maxHeight: 512, quality: 100, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }`} render={data => {
        if (data.allNodeArticle.edges.length > 0) {
          let article = data.allNodeArticle.edges[0].node;
          return (<section id="banner">
            <div className="content">
              <header>
                <h1>{article.title}</h1>
                <p>{article.field_subtitle}</p>
              </header>
              <p dangerouslySetInnerHTML={{ __html: article.body.summary }}></p>
              <ul className="actions">
                <li>
                  <Link to={ article.path.alias ? article.path.alias : '/article/' + article.id } className="button big">Читать Дальше</Link>
                </li>
              </ul>
            </div>
            <span className="image object">
              <Img fluid={ article.relationships.field_cover.relationships.field_media_image.localFile.childImageSharp.fluid } />
            </span>
          </section>)
        }
        else {
          return (<section id="banner">
            <div className="content">
              <header>
                <h1>Welcome to our blog</h1>
                <p>There are no articles yet, probably I'm writing them as you read.</p>
              </header>
              <p>Please come back shortly for some more articles.</p>
            </div>
          </section>)
        }
      }}/>
    )
  }
}

export default ArticleFeatured
