import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Pagination = ({ numberPages, currentPage, subPage }) => {
  let pageNumbers = [];
  if (numberPages >= 4) {
    let first = [1, 2];
    let last = [];
    last.push(numberPages - 1);
    last.push(numberPages);


    if (!first.includes(currentPage) && !last.includes(currentPage)) {
      if (currentPage >= 5) {
        first.push('…');
      }

      if (!first.includes(currentPage - 1)) {
        first.push(currentPage - 1);
      }

      first.push(currentPage);

      if (!last.includes(currentPage + 1)) {
        first.push(currentPage + 1);
      }

      if (currentPage < numberPages - 3) {
        first.push('…');
      }
    }
    else if (currentPage === 2) {
      if (!last.includes(currentPage + 1)) {
        first.push(currentPage + 1);
        first.push('…');
      }
    }
    else if (currentPage === numberPages - 1) {
      if (!first.includes(currentPage - 1)) {
        first.push('…');
        first.push(currentPage - 1);
      }
    }
    else {
      first.push('…');
    }
    pageNumbers = first.concat(last);
  }
  else {
    for (let i = 1; i <= numberPages; i++) {
      pageNumbers.push(i);
    }
  }

  const renderPageNumbers = pageNumbers.map((number, index) => {
    let url = number === 1 ? subPage.slice(0, -4) : subPage + '/' + number;
    return (
      <li key={index} >
        { !isNaN(number) ?
          (<Link to={url} className={number === currentPage ? 'page active' : 'page'}>{number}</Link>)
          : (<span className="page">{number}</span>)}
      </li>
    );
  });

  return (
    <ul className="pagination">
      { currentPage === 1 ? (
        <li><span className="button disabled">НАЗАД</span></li>
      ) : (
        (currentPage - 1) === 1 ? (
          <li><Link to={subPage} className="button">НАЗАД</Link></li>
          ) : (
          <li><Link to={subPage + '/' + (currentPage - 1)} className="button">НАЗАД</Link></li>
        )
      )}

      {renderPageNumbers}
      { currentPage === numberPages ? (
        <li><span className="button disabled">ВПЕРЕД</span></li>
      ) : (
        <li><Link to={subPage + '/' + (currentPage + 1)} className="button">ВПЕРЕД</Link></li>
        )}
    </ul>
  )
}


Pagination.propTypes = {
  numberPages: PropTypes.number,
  currentPage: PropTypes.number,
  subPage: PropTypes.string
}

Pagination.defaultProps = {
  numberPages: 0,
  currentPage: 0,
  subPage: '/page'
}

export default Pagination
