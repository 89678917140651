import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const ArticleTeaser = ({data}) => {
  let node = (data.node !== undefined) ? data.node : data;
  let slug = node.path.alias ? node.path.alias : '/article/' + node.id;
  return (
    <article>
      <Link to={ slug } className="image"><Img fixed={ node.relationships.field_cover.relationships.field_media_image.localFile.childImageSharp.fixed } /></Link>
      <h3><Link to={ slug }>{ node.title }</Link></h3>
      <sup><small><em>{ new Date(node.created).toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' }) }</em></small></sup>
      <p dangerouslySetInnerHTML={{ __html: node.body.summary ? node.body.summary : node.body.value.split(' ').splice(0, 50).join(' ') + '...' }}></p>
      <ul className="actions">
        <li><Link to={slug} className="button">Читать Дальше</Link></li>
      </ul>
    </article>
  )
}

ArticleTeaser.propTypes = {
  data: PropTypes.object.isRequired
}
export default ArticleTeaser
