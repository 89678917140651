import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleFeatured from '../components/article-featured'
import ArticleTeaser from "../components/article-teaser"
import TagList from "../components/tag-list"
import Pagination from "../components/pagination"

const IndexPage = ({ pageContext, data }) => {
  var seoTitle = 'Главная';
  if (pageContext.currentPage !== 1) {
    seoTitle = 'Страница ' + pageContext.currentPage
  }
  return (
    <Layout>
      <SEO title={seoTitle} />
      { pageContext.currentPage === 1 && (
        <ArticleFeatured/>
      )}
      <section>
        <header className="major">
          { pageContext.currentPage === 1 ? (
            <h2>Актуальное</h2>
          ) : (
            <h2>Статьи: страница { pageContext.currentPage} из { pageContext.numPages }</h2>
          )}
        </header>
        <div className="posts">
          {data.allNodeArticle.edges.map(edge => {
              return <ArticleTeaser data={edge} key={edge.node.id}/>
            }
          )}
        </div>
        <div className="align-center">
          <Pagination currentPage={pageContext.currentPage} numberPages={pageContext.numPages} subPage='/page' />
        </div>
      </section>

      <header className="major">
        <h2>Теги и Категории</h2>
      </header>

      <div className="row">
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermTags.edges} name="Теги"/>
        </div>
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermCategory.edges} name="Категории"/>
        </div>
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermCountries.edges} name="Теги Стран"/>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
      query IndexPageQuery($limit: Int!, $skip: Int!) {
        allNodeArticle (limit: $limit, skip: $skip, sort: {order: [DESC, DESC], fields: [sticky, created]}, filter: { status: {eq: true}}) {
          edges {
            node {
              id,
              title
              body {
                summary
                value
              }
              created
              path {
                alias
              }
              relationships {
                field_cover {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 480, height: 350, quality: 100, cropFocus: CENTER) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        allTaxonomyTermTags(sort: {order: ASC, fields: name}) {
            edges {
              node {
                id
                name
                path {
                  alias
                }
              }
            }
          }
        allTaxonomyTermCategory(sort: {order: ASC, fields: name}) {
          edges {
            node {
              id
              name
              path {
                alias
              }
            }
          }
        }
        allTaxonomyTermCountries(sort: {order: ASC, fields: name}) {
          edges {
            node {
              id
              name
              path {
                alias
              }
            }
          }
        }
      }
    `
